import React from "react";
import { graphql } from "gatsby";
import IntroBlock from "@components/other/introBlock";
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line
import { PageMeta } from "@components/pageMeta";
import Layout from "@components/layout";
import SimplePortableText from "@components/sanity/simplePortableText";

const simplePageTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { seo, title, subtitle, text } = page || {};

  return (
    <Layout reversedHeader={true}>
      <PageMeta {...seo} />
      <IntroBlock title={title} subtitle={subtitle} />
      <div className="pt-space-m px-gutter">
        <SimplePortableText text={text} />
      </div>
    </Layout>
  );
};

export default simplePageTemplate;

export const simpePageQuery = graphql`
  query standardSimplePageQuery($slug: String!) {
    page: sanitySimplePage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      subtitle
      text: _rawText(resolveReferences: { maxDepth: 10 })
    }
  }
`;
